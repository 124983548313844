import { Injectable } from '@angular/core';
import { iif, Observable, of, zip } from 'rxjs';
import { DynamicAssetLoaderService } from '../dynamic-asset-loader/dynamic-asset-loader.service';
import { WindowService } from '../window.service';
import { SettingsService } from '../settings.service';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Member } from '@components/members';
import { MembersService } from '../members.service';
import { AppConfigService } from '../app.config.service';
import { ProductAnalytics } from '@interfaces/product-analytics.interface';
import { LocationService } from '../location/location.service';
import { NetworksService } from '../networks.service';
import { Network } from '@interfaces/network.model';
import { Place } from '@classes/place.class';
import { AuthService } from '../auth.service';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { AppConfig } from '@interfaces/app-config.model';
import { differenceInYears } from 'date-fns';
import { SearchType } from '@enums/search-type.enum';
import { QualtricsService } from '@directives/qualtrics/qualtrics.service';
import { find, some, startsWith } from 'lodash';
import { StorageUtilities } from '@utilities/storage.utilities';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProductAnalyticsService {
  private searchPrefix = 'Search ';
  private noResultsSearchPrefix = 'No Results Search ';
  private sessionStorage = new StorageUtilities();
  
  constructor(
    private assetLoader: DynamicAssetLoaderService,
    private windowService: WindowService,
    private settingsService: SettingsService,
    private membersService: MembersService,
    private appConfigService: AppConfigService,
    private locationService: LocationService,
    private networksService: NetworksService,
    private criticalParamsService: CriticalParamsService,
    private authService: AuthService,
    private qualtricsService: QualtricsService,
    private router: Router,
  ) {}

  /**
   * Sends ProductAnalytics and Pendo member and session data if configured.
   * @returns Observable<boolean>
   */
  public initialize(): Observable<ProductAnalytics> {
    return this.settingsService.getSetting('initialize_analytics').pipe(
      switchMap((shouldLoad: boolean) => {
        if (shouldLoad && !this.bannedUserAgentDetected()) {
          return this.initializeWithData();
        }
        return of(null);
      }),
      tap((data) => this.sendData(data)),
      switchMap((data) => this.qualtricsService.initializeQualtrics(data).pipe(map(() => data)))
    );
  }

  public sendTrackEvent(name, props) {
    this.handleSearchTracking(name, props);
    this.windowService['pendo']?.track(name, props);
  }

  public buildSearchTrackEventName(noResults: boolean, type: SearchType): string {
    return (noResults ? this.noResultsSearchPrefix : this.searchPrefix) + type;
  }
  
  public setAuthenticationPage(cta = null) {
    const currentPage = this.getCurrentPageName() || 'Home';
    this.sessionStorage.sessionStorageSet('page_authenticated', cta || currentPage);
  }
  
  public handleAuthenticationTracking(resolvedNetwork: string, pageLanded: string) {
    const eventData = {
      selected_network: resolvedNetwork,
      page_authenticated: this.sessionStorage.sessionStorageGet('page_authenticated'),
      page_landed: pageLanded,
    };
    
    this.sendTrackEvent('Authenticated Member Network', eventData);
    this.sessionStorage.sessionStorageSet('authentication_tracked', true);
    this.sessionStorage.sessionStorageRemove('page_authenticated');
  }

  private getCurrentPageName(): string {
    const pageMap = {
      search: 'Search Results',
      profile: 'Provider Profile',
    };
    const key = find(Object.keys(pageMap), pageKey => this.router.url.includes(`/${pageKey}`));
    return pageMap[key];
  }
  
  private handleSearchTracking(name: string, props: any): void {
    const isSearchEvent = some(SearchType, (type) =>
      startsWith(name, this.searchPrefix + type) ||
      startsWith(name, this.noResultsSearchPrefix + type) || 
      props.searchMethod === 'Autosuggest'
    )

    if (isSearchEvent) {
      this.qualtricsService.updateMetadata({ searchName: name, ...props })
    }
  }

  private loadPendo(): Observable<boolean> {
    return this.assetLoader.loadAsset(
      '../../../assets/scripts/pendo.js',
      'script'
    );
  }

  private initializeWithData(): Observable<ProductAnalytics> {
    return this.loadPendo().pipe(
      switchMap((loaded) =>
        iif(() => loaded, this.getProductAnalyticData(), of(null))
      )
    );
  }

  private getProductAnalyticData(): Observable<ProductAnalytics> {
    return this.appConfigService.config.pipe(
      switchMap((appConfig) =>
        this.authService.authStatus.pipe(
          first((auth) => auth.resolved),
          switchMap((auth: AuthStatus) =>
            zip(
              of(auth),
              this.getMember(auth),
              this.locationService.geo.pipe(
                filter((geo) => !!geo),
                distinctUntilKeyChanged('geo')
              ),
              this.networksService.resolvedNetwork.pipe(
                distinctUntilKeyChanged('id')
              ),
              this.criticalParamsService.criticalParamsSubject.pipe(
                distinctUntilChanged()
              )
            )
          ),
          map(([authStatus, member, location, network, criticalParams]) => {
            const productAnalyticsData = this.setData(
              authStatus,
              location,
              network,
              criticalParams.ci,
              appConfig
            );
            if (member) {
              this.extendWithMemberData(member, productAnalyticsData, appConfig.environment);
            }
            return productAnalyticsData;
          })
        )
      )
    );
  }

  private getMember(auth: AuthStatus): Observable<Member> {
    if (auth.auth_status) {
      return this.membersService.member.pipe(
        first((member) => !!member),
        distinctUntilKeyChanged('subscriber_id')
      );
    }
    return of(null);
  }

  private setData(
    auth: AuthStatus,
    location: Place,
    network: Network,
    ci: string,
    appConfig: AppConfig
  ): ProductAnalytics {
    const screen = `${this.windowService['screen'].width}x${this.windowService['screen'].height}`;
    const browser = `${this.windowService['visualViewport'].width}x${this.windowService['visualViewport'].height}`;
    return {
      visitor: {
        authStatus: auth.auth_status,
        msaStatus: auth.msa_auth_status,
        geolocation: `${location.city}, ${location.state_code}`,
        network: network.id,
        screenResolution: screen,
        browserWindow: browser,
      },
      account: {
        id: ci,
        client: appConfig.client,
      },
    };
  }

  private extendWithMemberData(member: Member, data: ProductAnalytics, environment: string): any {
    const memberData = {
      id: this.addPrefixToMemberId(environment, member.id),
      age: this.getAge(member.dob),
      gender: member.gender,
      medFinderEligible: member.rx_savings_eligible,
      qrEligibleMember: member.quality_rewards_eligible
    };
    return (data.visitor = { ...data.visitor, ...memberData });
  }

  private sendData(data: ProductAnalytics): void {
    if (!data) {
      return;
    }
    this.windowService['pendo'].initialize(data);
  }

  private getAge(dob: string): number {
    return differenceInYears(new Date(), new Date(dob));
  }

  private bannedUserAgentDetected(): boolean {
    const currentUserAgent = this.windowService['navigator'].userAgent;
    let isBanned: boolean = false;

    const bannedUserAgents = [
      'Pingdom',
      'DatadogSynthetics',
    ];

    bannedUserAgents.forEach(bannedUserAgent => {
      if (currentUserAgent.indexOf(bannedUserAgent) !== -1) {
        isBanned = true;
      };
    });

    return isBanned;
  }

  private addPrefixToMemberId(environment: string, memberId: string): string {
    if (environment !== 'production') {
      return `${environment}_${memberId}`;
    }
    return memberId;
  }
}
